import * as React from 'react';
import { Route, Routes } from 'react-router';
import Home from './components/Home';
import { Failed } from './components/Failed';
import { Dashboard } from './components/Dashboard';


import './custom.css'

export default () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/failed" element={<Failed />} />
    <Route path="/dashboard" element={<Dashboard />} />
  </Routes>
);
