import React, { FunctionComponent } from 'react';
import { Banner } from '@shopify/polaris';


export const Failed: FunctionComponent = ({
}) => { 
  
  return (
 
            <Banner
              title="Error with installation"
              tone="warning"
            >
<p>There seems to be an error in your installation. You need to uninstall this app and reinstall it again.</p>
<p>We apologize for the inconvenience</p>
            </Banner>

 
  )
}