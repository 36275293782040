import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState, setToken as setTokenAction } from 'store';
import queryString from 'query-string';

export const useToken = () => {
    const [token, setToken] = useState<string | undefined>();
    const location = useLocation();
    const parsedParams = queryString.parse(location.search);

    // S�kerst�ll att tokenFromParams �r en str�ng
    const tokenFromParams = Array.isArray(parsedParams.token)
        ? parsedParams.token[0]
        : parsedParams.token;

    const tokenFromStore = useSelector((state: ApplicationState) => state?.token?.token);
    const dispatch = useDispatch();

    useEffect(() => {
        if (typeof tokenFromParams === 'string' && tokenFromParams !== tokenFromStore) {
            dispatch(setTokenAction(tokenFromParams));
            setToken(tokenFromParams);
        } else {
            setToken(tokenFromStore);
        }
    }, [tokenFromParams, tokenFromStore]);

    return token;
}
