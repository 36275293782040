import '@shopify/polaris/build/esm/styles.css';
import { AppProvider, Page } from '@shopify/polaris';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import configureStore from './store/configureStore';
import App from './App';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
      <BrowserRouter basename={baseUrl}>
      <AppProvider
  i18n={{
    Polaris: {
      ResourceList: {
        sortingLabel: 'Sort by',
        defaultItemSingular: 'item',
        defaultItemPlural: 'items',
        showing: 'Showing {itemsCount} {resource}',
        Item: {
          viewItem: 'View details for {itemName}',
        },
      },
      Common: {
        checkbox: 'checkbox',
      },
    },
  }}
>
  

                <App/>
                </AppProvider>
      </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// registerServiceWorker();
