import React, { useState, useEffect, FunctionComponent, PropsWithChildren } from 'react';
import { useToken } from 'integrations/crossborderit';
import { Switch, Case } from 'components/shared';

enum TokenStatus {
  NoTokenProvided,
  TokenProvided
}

export const TokenGuard: FunctionComponent<PropsWithChildren> = ({
  children
}) => {
  const token = useToken();
  const [tokenStatus, setTokenStatus] = useState(TokenStatus.NoTokenProvided);

  useEffect(() => {

    const tokenStatus = token
      ? TokenStatus.TokenProvided
      : TokenStatus.NoTokenProvided;

    setTokenStatus(tokenStatus);

  }, [token]);

  return (
    <Switch on={tokenStatus}>

      <Case match='0'>
      <p></p>
      </Case>

      <Case match='1'>
        {children}
      </Case>

    </Switch>
  )
}
