import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { homeReducer } from './home';
import { tokenReducer } from './token';
import { ApplicationState } from './interfaces';

export const reducers = {
  home: homeReducer,
  token: tokenReducer,
};

export default function configureStore(initialState?: ApplicationState) {
    const middleware = [
        thunk,
    ];

    const rootReducer = combineReducers({
        ...reducers,
    });

    const enhancers = [];
    const windowIfDefined = typeof window === 'undefined' ? null : window as any; // eslint-disable-line @typescript-eslint/no-explicit-any
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }

    return createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
}
